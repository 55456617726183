.header-nav.header-nav-static {
  position: fixed;
  top: 0;
  padding: 0;
  border-radius: 0;
  background: white !important;
  z-index: 99;
}

body.preview-open {
  header {
    z-index: 1 !important;
  }
  main {
    z-index: 2 !important;
  }
}

#top {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    z-index: 2;

    a {
        font-size: 13px;
        color: white;
        text-decoration: none;
        line-height: 40px;

        &:hover {
            text-decoration: underline;
        }
    }
}

header {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
  z-index: 2;

  .navbar-toggler {
    margin: 29px 0;
  }

  .main-navigation {
	float: right;
    padding: 30px 0;
    margin: 0;
    border-radius: 0;

    ul {
      display: block;
      font-size: 16px;
      font-weight: 400;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        float: none !important;
        display: inline-block;
        margin-right: 15px;
        margin-left: 0 !important;

        &:last-child {
          margin-right: 0;
        }

        a {
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }
    ul.header-social {
      font-size: 24px;
      line-height: 38px;

      li {
        margin-right: 5px;

        a {
          color: #666666;

          &:hover,
          &.active {
            color: #7aabcc;
          }
        }
      }
    }


  }

  .logo {
    max-height: 75px;
    width: auto;
    margin: 10px 0;
  }
}

@media screen and (max-width: 991px) {
	#menu-collapse {
      float: left;
      position: absolute;
      left: 0;
      width: 100%;
      margin: 0;
	  padding: 0;
      z-index: 999;
	  top: 100%;
	  border-radius: 0;

      ul {
        display: block;
        font-size: 18px;
        font-weight: 400;
        list-style: none;
        margin: 0;
        padding: 0;
		width: 100%;

        li {
          display: block;
          float: none !important;
          margin-left: 0;
		  margin-right: 0;

          a {
            display: block;
            color: white;
            padding: 10px 15px;
            text-transform: uppercase;
          }
        }
      }

    }
}

.grid {
  width: 103.125%;
  overflow: hidden;
  margin-left: -1.562%;
  margin-bottom: -1.875em;
  margin-top: 30px;

  .grid-item {
    width: 30.303%;
    float: left;
    margin: 0 1.515% 1.875em;

    img {
      max-width: 100%;
      height: auto;
    }

    .gallery-slot {
      img {
        width: 100%;
        max-height: 300px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
      .gallery-header {
        display: block;
        padding: 15px;
        border: 1px solid #eee;

        h3 {
          font-family: $secondary-font;
          font-size: 28px;
          margin: 0;
        }

        ul.meta {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: inline;
            font-size: 13px;
            margin-right: 10px;

            i {
              padding-right: 3px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .gallery-body, .gallery-footer {
        display: block;
        padding: 15px;
        border: 1px solid #eee;
        border-top: 0;

        p:last-child {
          margin: 0;
        }
      }
    }

  }
}

@media only screen and ( max-width: 768px ) {
  .grid {
    margin-bottom: -0.938em;

    .grid-item {
      width: 46.876%;
      margin-bottom: 0.938em;
    }
  }
}
@media only screen and ( max-width: 520px ) {
  .grid {
    width: 100%;
    margin-left: 0;

    .grid-item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.grid2 {
  position: relative;
  width: 103.125%;
  overflow: hidden;
  margin-left: -1.562%;
  margin-bottom: -1.875em;
  margin-top: 30px;
}

.js .grid2::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background: #252323 url(../img/loading.svg) no-repeat 50% 75px;
  background-size: 60px auto;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.js .grid--loaded::after {
  opacity: 0;
}

.grid__item {
  width: 30.303%;
  float: left;
  margin: 0 1.515% 1.875em;

  img {
    max-width: 100%;
    height: auto;
  }
}

@media only screen and ( max-width: 768px ) {
  .grid2 {
    margin-bottom: -0.938em;

    .grid__item {
      width: 46.876%;
      margin-bottom: 0.938em;
    }
  }
}
@media only screen and ( max-width: 520px ) {
  .grid2 {
    width: 100%;
    margin-left: 0;

    .grid__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.grid__item--current {
  opacity: 0 !important;
}

.img-wrap {
  display: block;
}

.img-wrap:focus,
.img-wrap:hover {
  outline: none;
}

.img-wrap img {
  display: block;
  max-width: 100%;
}

.preview {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.preview--open {
  pointer-events: auto;
}


/* circle overlay (generated SVG) */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.overlay circle {
  -webkit-transition: fill-opacity 0.3s, -webkit-transform 0s 0.3s;
  transition: fill-opacity 0.3s, transform 0s 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transform: scale3d(0, 0, 1);
  transform: scale3d(0, 0, 1);
  fill: #1f1d1d;
  fill-opacity: 0;
}

.preview--open .overlay circle {
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  fill-opacity: 1;
}

.clone {
  position: fixed;
  z-index: 110;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.5, 1, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.5, 1, 0.2, 1);
  -webkit-backface-visibility: hidden;
}

.original {
  position: relative;
  z-index: 120;
  display: block;
  object-fit: contain;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-backface-visibility: hidden;
}

.preview--open .animate {
  /* open */
  -webkit-transition: -webkit-transform 0.6s, opacity 0.2s;
  transition: transform 0.6s, opacity 0.2s;
}

.animate {
  /* close */
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.description {
  color: #fff;
}

.js .description--grid {
  display: none;
}

.description--preview {
  position: fixed;
  z-index: 140;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2em 1em;
  text-align: center;
  opacity: 0;
  background: linear-gradient(180deg, transparent, #1f1d1d);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.preview--open .description--preview {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}


/* Close button */

.action {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: top;
  color: #7aabcc;
  border: none;
  background: none;
}

.action:hover,
.action:focus {
  color: #7aabcc;
  outline: none;
}

.action--close {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  padding: 1em;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: scale3d(0.6, 0.6, 1);
  transform: scale3d(0.6, 0.6, 1);
}

.preview--image-loaded .action--close {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

body {

    @include build-theme() {

        .body-title {
            color: map-fetch($config, skins $skin primary  );
        }

        .btn-primary {
            background: map-fetch($config, skins $skin primary  );
            border-color: map-fetch($config, skins $skin primary  );
            color: white;

            &:hover,
            &:focus,
            &:active {
                background: white;
                border-color: map-fetch($config, skins $skin primary  );
                color: map-fetch($config, skins $skin primary  );
            }
        }

        .btn-primary-outline {
            color: map-fetch($config, skins $skin primary  ) !important;
            border-color: map-fetch($config, skins $skin primary  ) !important;

            &:hover, &:active {
                color: white !important;
                background-color: map-fetch($config, skins $skin primary  ) !important;
            }
        }

        #top {
            background: map-fetch($config, skins $skin primary  );
        }

        header#header {
        	border-bottom: 2px solid map-fetch($config, skins $skin primary  );

            .main-navigation {
              ul {
                li {
                  a {
                    color: map-fetch($config, skins $skin quinary  );

                    &:hover,
                    &.active {
                      color: map-fetch($config, skins $skin primary  );
                    }
                  }
                }
              }
              ul.header-social {
                li {
                  a {
                    color: map-fetch($config, skins $skin quaternary  );

                    &:hover,
                    &.active {
                      color: map-fetch($config, skins $skin primary  );
                    }
                  }
                }
              }
            }
        }

        @media (max-width: 991px) {
            header#header {
                #menu-collapse {
                  background:  map-fetch($config, skins $skin tertiary);

                  ul {
                    li {
                      a {
                          color: darken(white, 30%);

                        &:hover,
                        &.active {
                          background:  map-fetch($config, skins $skin primary);
                          color: white;
                        }
                      }
                    }
                  }

                }
            }
        }

        .slider > ul > li {
          &:before {
            background-color: map-fetch($config, skins $skin primary);
            background-image: -webkit-linear-gradient(bottom left, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
            background-image: -o-linear-gradient(bottom left, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
            background-image: linear-gradient(to top right, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
          }
        }

        #slidemenu {
          background: map-fetch($config, skins $skin primary  );

          ul {
            li {
              a {
                &:hover {
                  .container-icon {
                      i {
                        color: white;
                      }
                  }
                }
              }

              &.current {
                a {
                  .container-icon {
                      i {
                        color: map-fetch($config, skins $skin primary  );
                      }
                  }
                }
              }
            }
          }
        }

        .nextBtn:hover, .prevBtn:hover {
          background: map-fetch($config, skins $skin primary);
        }

        main#main {

            .breadcrumbs {
              background: map-fetch($config, skins $skin secondary);

              ul {
               li {
                 a {
                     &:hover {
                         color: map-fetch($config, skins $skin primary);
                     }
                 }
                 &.active {
                     color: map-fetch($config, skins $skin quaternary);
                 }
                }
             }
            }


          .pricing-box {
            .pricing-button {
              background: map-fetch($config, skins $skin primary  );
            }
          }

            a, a:visited {
                color: map-fetch($config, skins $skin primary  );
            }

        	.feature {
        		h2 {
        			&:before, &:after {
        			    border-bottom: 1px solid  map-fetch($config, skins $skin primary);
        			}
        		}

        		.service-tabs {
        			.nav-item {
        				&.active {
        					border-color: map-fetch($config, skins $skin primary);

        					.nav-link {
        						color: map-fetch($config, skins $skin primary);
        					}

        					&:after {
        						border-color: map-fetch($config, skins $skin primary) transparent transparent transparent;
        					}
        				}
        			}
        		}

        		.service-tabs-content {
        			h3 {
        				color: map-fetch($config, skins $skin primary);
        			}

        			ul.webicons {
        				li {
        					.icon-container {
        						border: 4px solid map-fetch($config, skins $skin primary);
        						color: map-fetch($config, skins $skin primary);

        						&:hover {
        							background: map-fetch($config, skins $skin primary);
                                    color: white;
        						}
        					}
        				}
        			}
        		}

        		article.news {
        			header {
        				h2 {
        					background-color: map-fetch($config, skins $skin primary);
        				}
        			}
        			footer {
        				.news-btn {
        					background: map-fetch($config, skins $skin primary);

        					&:hover {
        						border-color: map-fetch($config, skins $skin primary);
        						color: map-fetch($config, skins $skin primary);
                                background: white;
        					}
        				}
        			}
        		}
        	}

        	.hero-container {
        		background: map-fetch($config, skins $skin primary);

        		.hero-btn {
        			color: map-fetch($config, skins $skin primary);

        			&:hover {
        				background: map-fetch($config, skins $skin primary);
                        color: white;
        			}
        		}

        		&.invert {
        			color: map-fetch($config, skins $skin primary);
                    background: white;

        			.hero-btn {
        				background: map-fetch($config, skins $skin primary);
        				border-color: map-fetch($config, skins $skin primary);
                        color: white;

        				&:hover {
        					color: map-fetch($config, skins $skin primary);
                            background: white;
        				}
        			}
        		}
        	}

            .testimonials {
              h2 {
                color: map-fetch($config, skins $skin tertiary);
              }

              .testimonial-slider {
                .testimonial {
                  p.message {
                    color: map-fetch($config, skins $skin quaternary);
                  }
                  .name {
                    color: darken(map-fetch($config, skins $skin tertiary), 10%);
                  }
                }
              }
              .testimonial-slider:before,
              .testimonial-slider:after {
                color: map-fetch($config, skins $skin quaternary);
              }
            }

            #maps-container {
                border-bottom: 2px solid map-fetch($config, skins $skin primary);
            }

        }

        .modal {
          .modal-dialog {
            .modal-content {
              .modal-header {
                background: map-fetch($config, skins $skin secondary);
                color: map-fetch($config, skins $skin tertiary);
              }
            }
          }
        }

        .study-controls {
          button {
            border: 1px solid map-fetch($config, skins $skin primary);
            color: map-fetch($config, skins $skin primary);

            &:hover,
            &.active {
              background: map-fetch($config, skins $skin primary);
            }
          }
        }
        .study-container {
           .mix {
            .content {
              background: map-fetch($config, skins $skin primary);
            }
          }
           .mix.web-development .content {
            border-top: 4px solid map-fetch($config, skins $skin tertiary);
            .content-overlay {
              .case-btn {
                color: map-fetch($config, skins $skin tertiary);
              }
            }
          }
        }

        footer  {
        	background: map-fetch($config, skins $skin secondary);
            border-top: 2px solid map-fetch($config, skins $skin primary  );

              .footer-links {
                li {
                  a {
                    &:hover {
                      color: map-fetch($config, skins $skin quaternary);
                    }
                  }
                }
                li.link-title {
                  color: map-fetch($config, skins $skin quaternary);
                  border-bottom: 1px solid map-fetch($config, skins $skin quaternary);
                }
              }

              .footer-foot {
                .social {
                  li {
                    a {
                      color: map-fetch($config, skins $skin quaternary);

                      &:hover {
                        color: map-fetch($config, skins $skin primary);
                      }
                    }
                  }
                }
                p.copy {
                  color: map-fetch($config, skins $skin quaternary);
                }
              }

        }

    }

}

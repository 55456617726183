/* Colour Theme Global Skin colours passed in from above variables */

/* Each pallete specific colours */
$skin-color: '';
$config: (
    skins: (
        
       purple: (
           primary: #a67acc,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

       orange: (
           primary: #ccb47a,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

       green: (
           primary: #7accac,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

       red: (
           primary: #cc7a7a,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

       blue: (
           primary: #7aabcc,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

       pink: (
           primary: #cc7aa8,
           secondary: #f5f5f5,
           tertiary: #444444,
           quaternary: #666666,
           quinary: #888888,
       ),

    )
)
;


@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);
    @if ($length > 1) {
        $rest: ();
        @for $i from 2 through $length {
            $rest: append($rest, nth($keys, $i))
        }
        @return map-fetch($value, $rest)
    } @else {
        @return $value;
    }
}

@mixin build-theme ($config: $config) {
    @each $skin in map-keys(map-get($config, skins)) {
        &.theme-#{$skin} {
            $skin: $skin !global;
            @content
        }
    }
}

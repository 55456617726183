.slider-container {
    position: relative;
    height: 70vh;
    min-height: 800px;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
  }

  .slider {
    position: relative;
    width: 100%;
    height: 100%;

    .slider-contents {
      position: relative;
      width: 100%;
      height: 100%;

      li {
        position: relative;
        width: 100%;
        height: 100%;

        .slider-row {
          position: relative;
          height: 100%;
          width: 100%;

          .slider-box {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            padding: 25px;
            background: rgba(0,0,0,.5);
            color: white;
            text-align: center;

            h2 {
              font-family: $secondary-font;
              font-size: 48px;
              margin-bottom: 15px;
            }
          }

          .img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-repeat: none;
            background-attachment: scroll;
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            -o-background-size: cover;
          }

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    & + .controls {
      a {
        font-size: 36px;
        position: absolute;
        top: 50%;
        padding: 25px 15px;
        background: rgba(0,0,0,.6);
        color: white !important;
        margin-top: -52px;

        &.nextBtn {
          position: absolute;
          right: 0;
        }
      }
    }

  }

  @media screen and (max-width: 768px) {
  main .slider-container, main .slider-container .slider, main .slider-container .slider .slider-contents, main .slider-container .slider .slider-contents li, main .slider-container .slider .slider-contents li .slider-row {
    height: 480px;
    min-height: 480px;
  }
}

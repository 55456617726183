.breadcrumbs {
  display: block;
  padding: 15px;
  background: #f5f5f5;
  margin: 30px 0 0 0;

  ul {
   font-size: 14px;
   display: block;
   list-style: none;
   margin: 0;
   padding: 0;

   li {
     display: inline;

     a {
         color: #a1a1a1;

         &:hover {
             text-decoration: none;
             color: #7aabcc;
         }
     }

     &.active {
         color: #666;
     }

     &:after {
         content: ">";
         padding: 0 10px;
         color: #ccc;
     }

     &:last-child:after {
         content: "";
         padding: 0;
     }

    }
 }
}

main {
	.image-feature {
    display: block;
    position: relative;
    margin: 0 0 30px 0;
    overflow: hidden;

    a {
      img {
        max-width: 100%;
        height: auto;
        opacity: 0.7;
      }
      figure {
        margin: 0 !important;
      }
      figcaption {
        font-size: 14px;
        color: #1a1a1a;
        font-weight: 400;
        display: block;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.7;
      }

      &:hover {
        text-decoration: none;
      }
      &:hover figcaption {
        opacity: 1;
      }
      &:hover img {
        opacity: 1;
      }
    }

  }

  .polariod, .about-feature {
    display: block;
    padding: 25px;
  }

  .polariod {
    h2, .tag {
      text-align: center;
    }
    h2 {
      font-size: 28px;
      margin: 15px 0 5px 0;
	  color: #444444;
    }
    .tag {
      font-size: 13px;
      font-weight: 300;
      text-transform: uppercase;
      color: #666666;
    }
    .tag {
      display: block;
    }
  }

  .about-feature {
    h1 {
      font-size: 36px;
      color: #333333;
    }
    p {
      font-size: 13px;
      color: #666666;
    }
	hr {
		margin-bottom: 0;
	}
  }

  .introduction {
    text-align: center;
    margin-top: 30px;

    h1 {
      font-size: 36px;
    }
    p {
      font-size: 13px;
      color: #666;
      margin: 15px 0 0 0;
    }
  }
  .introduction.bg {
    padding: 25px;
    background: #f5f5f5;
  }
}

@include media-breakpoint-down(md) {
  main {
    .image-feature {
      margin-bottom: 15px;

      a {
        img {
          opacity: 1;
          margin-left: -10px;
        }
        figcaption {
          opacity: 1;
        }
      }
    }
    .about-feature {
      text-align: center;

	  &.home {
		   padding-top: 0;
	  }
    }
  }
}
@include media-breakpoint-down(sm) {
  main {
    #pricing-table {
      .plan {
        width: 100%;
      }
      #most-popular {
        top: 0;
      }
    }
  }
}

.text-hidden {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
  color: transparent;
}

.testimonials {
  display: block;
  text-align: center;

  h2 {
    margin-bottom: 30px;
    color: #444;
  }

  .testimonial-slider,
  .owl-item {
    width: 100%;
    margin-top: 15px;
  }
  .testimonial-slider {

    .testimonial {
      p.message {
        font-size: 13px;
        font-weight: 300;
        color: #666666;
        line-height: 18px;
      }
      .name {
        font-family: $secondary-font;
        font-size: 24px;
        color: #333333;
      }
    }
  }
  .testimonial-slider:before,
  .testimonial-slider:after {
    position: absolute;
    font-family: FontAwesome;
    font-size: 36px;
    color: #666666;
    font-weight: 400;
    top: -30px;
  }
  .testimonial-slider:before {
    content: "\f10d";
    left: 0;
  }
  .testimonial-slider:after {
    content: "\f10e";
    right: 0;
  }
}

@include media-breakpoint-up(md) {
  .testimonial-slider {
      position: relative;
      width: 75% !important;
      left: 12.5%;
  }
  .testimonial-slider:before {
    left: -45px !important;
  }
  .testimonial-slider:after {
    right: -45px !important;
  }
}

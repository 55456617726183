footer {
  display: block;
  width: 100%;
  background: #f5f5f5;
  padding: 25px;
  border-top: 4px solid #444444;
  margin: 30px 0 0 0;

  .footer-links {
    display: block;
    list-style: none;
    font-size: 13px;
    font-weight: 300;
    color: #a1a1a1;
    text-transform: uppercase;
    margin: 0 0 30px 0;
    padding: 0;

    &.reviews {
      text-transform: none !important;

      li {
        margin-bottom: 10px;
        &.link-title {
          text-transform: uppercase !important;
        }

        .message,
        .meta {
          display: block;
        }
        .meta {
          margin: 3px 0;
          color: #666666;

          &:before {
            content: "- ";
          }
          .stars {
            i {
              color: #a1a1a1;

              &.fill {
                color: #e7c93c;
              }
            }
          }
        }
      }
    }

    li {
      display: block;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #a1a1a1;

        &:hover {
          color: #666666;
        }
      }
    }
    li.spacer {
      margin-bottom: 10px !important;
    }
    li.link-title {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      padding-bottom: 5px;
      border-bottom: 1px solid #666666;
      margin-bottom: 15px !important;
    }
  }
  .advert {
    max-width: 100%;
    height: auto;
  }

  .footer-foot {
    display: block;
    text-align: center;

    .social {
      display: block;
      list-style: none;
      font-size: 24px;
      padding: 0;
      margin: 30px 0 10px 0;

      li {
        display: inline-block;
        margin-right: 5px;

        a {
          color: #666666;

          &:hover {
            color: #7aabcc;
          }
        }
      }
    }
    p.copy {
      display: block;
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: 100;
      color: #666666;
    }
  }

}

$skin-font:      'Raleway', 'Sacramento', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$secondary-font: 'Sacramento', 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

html, body {
  font-family: $skin-font;
  font-weight: 300;
  font-size: 16px;
  color: #444444;
  background: white;
  width: 100%;
  overflow-x: hidden;
}

h1 {
  font-size: 1.625rem;
}
h2 {
  font-size: 1.375rem;
}
h3 {
  font-size: 1.125rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.875rem;
}
h6 {
  font-size: 0.813rem;
}
p {
  font-size: 0.875rem;
}

.block {
  display: block !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt1rem {
  margin-top: 1rem !important;
}
.mt2rem {
  margin-top: 2rem !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb1rem {
  margin-bottom: 1rem !important;
}
.mb25 {
  margin-bottom: 25px;
}
.pt0 {
  padding-top: 0 !important;
}
.bb0 {
  border-bottom: none !important;
}
.p25 {
  padding: 25px !important;
}

.bg-primary {
    color: #444 !important;
  background: #f5f5f5 !important;
}

.btn-square {
    border-radius: 0;
}

.ptitle {
  font-family: $skin-font !important;
}
.stitle {
  font-family: $secondary-font !important;
}

.f20 {
  font-size: 20px !important;
}
.f24 {
  font-size: 24px !important;
}

h2.stitle {
  font-size: 36px;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0,0,0,0.05);
}
.img-responsive-center {
  position: relative;
  max-width: 100%;

  &:empty {
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
}

input, textarea {
  border-color: rgba(0,0,0,.25) !important;
  border-radius: 0 !important;
}

// Imports
@import "header";
@import "slider";
@import "main";
@import "breadcrumbs";
@import "pricing-tables";
@import "image-grid";
@import "testimonials";
@import "contact";
@import "modal";
@import "footer";

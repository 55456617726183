.pricing-tables {
    position: relative;
    margin: 0 auto;
    text-align: center;
  }
  .pricing-table {
    width: 100%;

    .plan {
      text-shadow: 0 1px rgba(255,255,255,.8);
      background: #fff;
      border: 1px solid #ddd;
      color: #333;
      padding: 20px;
      width: 33.3333333333%; /* plan width = 180 + 20 + 20 + 1 + 1 = 222px */
      float: left;
      position: relative;

      .btn {
          color: white !important;
      }

      &.featured {
        z-index: 2;
        top: -13px;
        border-width: 3px;
        padding: 30px 20px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -moz-box-shadow: 20px 0 10px -10px rgba(0, 0, 0, .15), -20px 0 10px -10px rgba(0, 0, 0, .15);
        -webkit-box-shadow: 20px 0 10px -10px rgba(0, 0, 0, .15), -20px 0 10px -10px rgba(0, 0, 0, .15);
        box-shadow: 20px 0 10px -10px rgba(0, 0, 0, .15), -20px 0 10px -10px rgba(0, 0, 0, .15);
      }
    }
    .plan:nth-child(1) {
      -moz-border-radius: 5px 0 0 5px;
      -webkit-border-radius: 5px 0 0 5px;
      border-radius: 5px 0 0 5px;
    }
    .plan:nth-child(4) {
      -moz-border-radius: 0 5px 5px 0;
      -webkit-border-radius: 0 5px 5px 0;
      border-radius: 0 5px 5px 0;
    }
    h3 {
      font-size: 20px;
      font-weight: normal;
      padding: 20px;
      margin: -20px -20px 50px -20px;
      background: #f5f5f5;
    }
    .featured h3 {
      background: #7aabcc;
      color: white;
      margin-top: -30px;
      padding-top: 30px;
      -moz-border-radius: 5px 5px 0 0;
      -webkit-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
    }
    .plan:nth-child(1) h3 {
      -moz-border-radius: 5px 0 0 0;
      -webkit-border-radius: 5px 0 0 0;
      border-radius: 5px 0 0 0;
    }
    .plan:nth-child(4) h3 {
      -moz-border-radius: 0 5px 0 0;
      -webkit-border-radius: 0 5px 0 0;
      border-radius: 0 5px 0 0;
    }
    h3 span {
      display: block;
      font-size: 25px;
      line-height: 85px;
      color: #777;
      background: #fff;
      border: 5px solid #fff;
      height: 100px;
      width: 100px;
      margin: 10px auto -65px;
      -moz-border-radius: 100px;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      -moz-box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
      -webkit-box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
      box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
    }
    h3 span.from {
      position: relative;
      font-size: 20px;
      line-height: 25px;
      padding-top: 20px;
    }
    ul {
      margin: 20px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        border-top: 1px solid #ddd;
        padding: 10px 0;
      }
    }
  }

  @media (max-width: 767px) {
      .pricing-tables .pricing-table .plan {
        width: 100%;
      }
      .pricing-tables .pricing-table .plan.featured {
        top: 0;
      }
}

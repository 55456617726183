.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;

      .modal-header {
        padding: 25px;
        background: #f5f5f5;
        color: #444;
      }
    }
  }
}
